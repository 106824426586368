$max: 80;
$offset: 2;
$unit: 'px';
@mixin list-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit}!important;
        }
        $i: $i + $offset;
    }
}

.margin-no {
    margin: 0 !important;
}
.padding-no {
  padding: 0 !important;
}
// Margins
@include list-loop('.m-', 'margin');
@include list-loop('.m-t-', 'margin-top');
@include list-loop('.m-r-', 'margin-right');
@include list-loop('.m-b-', 'margin-bottom');
@include list-loop('.m-l-', 'margin-left');
// Paddings
@include list-loop('.p-', 'padding');
@include list-loop('.p-t-', 'padding-top');
@include list-loop('.p-r-', 'padding-right');
@include list-loop('.p-b-', 'padding-bottom');
@include list-loop('.p-l-', 'padding-left');


.padding-no-native::part(native) {
  padding: 0 !important;
}

.margin-h-auto {
  margin: 0 auto !important;
}

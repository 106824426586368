ion-modal.auto-height {
  --height: auto;
  --max-height: calc(100% - env(safe-area-inset-top) - 4px);
  flex: 1;

  &::part(content) {
    overflow: auto;
    display: flex;
  }

  .close-btn {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
  }

  & .ion-page {
    height: auto;
    max-height: 100%;
    --max-height: 100%;
    flex: 1 !important;

    .inner-content {
      //max-height: calc(100vh - 80px);
      overflow: auto;
    }
  }
}


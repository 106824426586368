.hb-input {
  --color: var(--ion-color-dark);
  &-no-border {
    --border-color: transparent;
  }

  &-no-padding-start {
    &::part(native) {
      padding-left: 0;
    }
  }
}

.flex {
    display: flex;

    &-1 {
        flex: 1;
    }

    &-3 {
      flex: 3;
    }

    &-row {
        display: flex;
        width: 100%;
    }

    &-direction-column {
      flex-direction: column;
    }

    &-wrap {
      flex-wrap: wrap;
    }
}

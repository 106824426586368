.text {
  &-decoration-underline {
    text-decoration: underline;
  }

  &-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.white-space {
  &-break {
    white-space: break-spaces;
  }

  &-no-wrap {
    white-space: nowrap;
  }

  &-normal {
    white-space: normal;
  }

  &-pre-wrap {
    white-space: pre-wrap;
  }
}

.word-break-keep-all {
  word-break: keep-all;
}

.line-height-normal {
  line-height: 1.4;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

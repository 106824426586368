body {
    margin: 0 auto;
    display: block;
    position: static;
}
.bg-none {
    background: none;
}

.sr-toolbar-bg-none {
  --ion-toolbar-background: transparent !important;
  --background: transparent;
}

#ForRadioOptions .alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}
.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.item-bg-transparent::part(native) {
    background: transparent;
}

.bg-light {
    background: var(--ion-color-light);
}

.bg-light-shade {
    --background: var(--ion-color-light-shade);
}

.min-w100 {
    min-width: 100%;
}

.w100 {
    width: 100%;
}

.w50 {
    width: 50%;
}

.h100 {
  height: 100%;
}

.border-radius-no {
    border-radius: 0;
}

.sr-block {
  display: block;
}

ion-item {
  --transition: none;
}


// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** pink **/
    --ion-color-pink: #ff41b2;
    --ion-color-pink-rgb: 255, 65, 178;
    --ion-color-pink-contrast: #fff;
    --ion-color-pink-contrast-rgb: 247, 175, 255, 100;
    --ion-color-pink-shade: #e0399d;
    --ion-color-pink-tint: #ff54ba;
    --ion-color-pink-positiv: #f7afff;
}


@font-face {
    font-family: 'made-the-artist';
    src: url('../assets/fonts/MADETheArtistScript.ttf');
}

@font-face {
    font-family: 'coco-gothic';
    src: url('../assets/fonts/CocoGothic_trial.ttf');
}

.made-the-start {
    font-family: 'made-the-artist';
}

.coco-gothic {
    font-family: 'coco-gothic';
}

.font-weight {
  &-300 {
    font-weight: 300!important;
  }
  &-400 {
    font-weight: 400!important;
  }
  &-500 {
    font-weight: 500!important;
  }
  &-700 {
    font-weight: 700;
  }
}

$max: 50;
$offset: 1;
$unit: 'px';
@mixin list-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit}!important;
        }
        $i: $i + $offset;
    }
}
@include list-loop('.font-size-', 'font-size');

.circle-header-btn {
  --border-radius: 50px;
  width: 33px!important;
  height: 33px!important;
  color: #fff;
  --icon-font-size: 20px;

  &::part(native) {
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.30);
  }
}

.circle-btn {
  width: 80px;
  height: 80px;
  border: 1px solid var(--ion-color-pink);
  border-radius: 50%;
  --border-radius: 50%;
}
